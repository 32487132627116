@import '../settings/colours.scss';

@mixin wrapper {
    overflow-x: hidden;
    width: 100%;

    &--light {
        background-color: $background-light;
        color: $background-light-text;
    }

    &--gray {
        background-color: $background-gray-dark;
        color: $background-gray-text;
    }

    &--dark {
        background-color: $background-dark;
        color: $background-dark-text;
    }
}