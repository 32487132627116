//Light
$background-light: #393939;
$background-light-text: #FFFFFFA4;

//Gray
$background-gray-light: #2A2A2A;
$background-gray-dark: #1E1E1E;
$background-gray-text: #FFFFFF;

//Dark
$background-dark: #0A0A0A;
$background-dark-text: #FFFFFFA4;

//global
$purple-light: #D420FF;
$purple-dark: #84239B;

$shadow-purple: #210e26A4;
$shadow-black: #121212D4;