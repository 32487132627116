@import "../../scss/lib.scss";

.c-main {
    &__wrapper {
        @include wrapper;
    }

    &__container {
        @include container;
    }

    & > div:not(.c-main__border) {
        position: relative;
        z-index: 0;
        padding-top: 40px;
        padding-bottom: 80px;

        @media only screen and (min-width: $bm-lg) {
            padding-top: 80px;
        }
    }

    &__anchor-offset {
        position: absolute;
        top: -66px;
    }

    &__border-line {
        border-radius: 1px;
        width: 100%;
        height: 2px;
        background-color: $purple-dark;
    }
}
