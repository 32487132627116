h1 {
    font-size: 72px;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: -0.002778em;
}

h2 {
    font-size: 56px;
    font-weight: 800;
    line-height: 64px;
    letter-spacing: -0.001786em;
}

h3 {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
}

@media only screen and (min-width: 992px) {
    h1.u-enlarge {
        font-size: 168px;
        line-height: 223px;
        font-weight: 900;
    }

    h2.u-enlarge{
        font-size: 72px;
        line-height: 80px;
        letter-spacing: -0.002778em;
    }

    h3.u-enlarge{
        font-size: 56px;
        line-height: 64px;
        letter-spacing: -0.001786em;
    }

    h4.u-enlarge {
        font-size: 40px;
        font-weight: 800;
        line-height: 48px;
    }
}

h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
}

label {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}

a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}

a:hover, a:focus {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}